export const HVolt = [
  {
    id: 1,
    img: [
      "https://ionvidle.sirv.com/krico/High%20voltage%20panel/load%20break%20switch.png",
    ],
    description: [],
    category: "hv",
    title: "Load Break Switch",
    spec: [
      { title: "Rating", value: "26kV, 800A, 26.3KA can withstand 3 Sec.50Hz" },
      {
        title: "Rated Lightning Impulse Voltage",
        value: "12/28/75kV Peak",
      },
      {
        title: "Degree of protection",
        value: "IP 4x / 5x",
      },
      {
        title: "Applicable standard",
        value: "IEC 62271-105 / 200",
      },
    ],
  },
  {
    id: 2,
    img: [
      "https://ionvidle.sirv.com/krico/High%20voltage%20panel/indoor%20vcb.png",
    ],
    description: [],
    category: "hv",
    title: "Indoor VCB",
    spec: [
      { title: "Types", value: "PLC Operating System" },
      {
        title: "Rating",
        value: "12kV to 36kV, upto 1200A, 26.3KA can withstand 3 Sec.50Hz",
      },
      {
        title: "Rated Lightning Impulse Voltage",
        value: "12/28/75kV Peak",
      },
      {
        title: "Degree of protection",
        value: "IP 4x / 5x",
      },
      {
        title: "Applicable standard",
        value: "IEC 62271-105 / 200",
      },
    ],
  },
  {
    id: 3,
    img: [
      "https://ionvidle.sirv.com/krico/High%20voltage%20panel/load%20break%20switch.png",
    ],
    description: [],
    category: "hv",
    title: "Load Break Switch",
    spec: [
      {
        title: "Rating",
        value: "26kV, 800A, 26.3KA can withstand 3 Sec.50Hz",
      },

      {
        title: "Rated Lightning Impulse Voltage",
        value: "12/28/75kV Peak",
      },
      {
        title: "Degree of protection ",
        value: "IP 4x / 5x",
      },
      {
        title: "Applicable standard",
        value: "IEC 62271-105 / 200",
      },
    ],
  },
  {
    id: 4,
    category: "hv",
    img: [
      "https://ionvidle.sirv.com/krico/High%20voltage%20panel/RING%20MAIN%20UNIT.png",
    ],
    description: [
      "SF6 gas insulation & current interruption – No fire hazard",
      "Maintenance free & sealed for life contact system",
      "Outdoor installation",
      "Robust construction",
      "Tee-off circuit breaker with integral protection system",
      "Low cost cable termination",
      "Fully extendable options",
      "Motorized mechanism",
    ],
    title: "Ring Main Unit",
    spec: [],
  },
  {
    id: 5,
    img: [
      "https://ionvidle.sirv.com/krico/High%20voltage%20panel/COMPACT%20SUBSTAION.png",
    ],
    description: [],
    category: "hv",
    title: "Compact Substation",
    spec: [
      { title: "Rated current  in HV Side", value: "11kV LBS / VCB" },
      {
        title: "Rated  current in Transformer side Transformer",
        value: "Upto2500KVA 11kV / 433VOCTC /OLTC, Dry / Oil cooled",
      },
      {
        title: "Rated  current in LV Side",
        value: "ACB, MCCB,APFC, DB,Sub Pane",
      },
      {
        title: "Applicable standard",
        value: "IEC 62271 – 202, IEC 1330(1995) IS 14786-2000",
      },
    ],
  },
];

export const MVolt = [
  {
    id: 6,
    img: [
      "https://ionvidle.sirv.com/krico/Medium%20voltage%20panel/AIR%20CIRCUIT%20BREAKER%20PANEL.png",
    ],
    description: [],
    category: "mv",
    title: "Air Circuit Breaker Panel",
    spec: [
      { title: "Types", value: "All types of Medium voltage distribution" },
      { title: "Rating", value: "63A to 6300A / 1100V" },
      { title: "System", value: "3 Phase 3 Wire or 4 Wire" },
      { title: "Operational Voltage", value: "440 V / 660 V" },
      { title: "Short Circuit Current", value: "36 KA / 50 KA for 1 Sec" },
      { title: "Degree of Production", value: "IP 42, IP 54, IP 55, IP 65" },
      { title: "Bus Bar", value: "Aluminum / Copper" },
      { title: "BIS Standard", value: "IEC 60204-1, 60439 – 1&2, IS: 8623" },
    ],
  },
  {
    id: 7,
    img: ["https://ionvidle.sirv.com/krico/low%20voltage%20panel/IMG-20241111-WA0002.jpg"],
    description: [],
    category: "mv",
    title: "Main Voltage Panel",
    spec: [
      { title: "Types", value: "All types of Medium voltage distribution" },
      { title: "Rating", value: "63A to 6300A / 1100V" },
      { title: "System", value: "3 Phase 3 Wire or 4 Wire" },
      { title: "Operational Voltage", value: "440 V / 660 V" },
      { title: "Short Circuit Current", value: "36 KA / 50 KA for 1 Sec" },
      { title: "Degree of Production", value: "IP 42, IP 54, IP 55, IP 65" },
      { title: "Bus Bar", value: "Aluminum / Copper" },
      { title: "BIS Standard", value: "IEC 60204-1, 60439 – 1&2, IS: 8623" },
    ],
  },
  {
    id: 8,
    img: [
      "https://ionvidle.sirv.com/krico/Medium%20voltage%20panel/SUB%20SWITCH%20BOARDS.png",
    ],
    description: [],
    category: "mv",
    title: "Sub Switch Board",
    spec: [
      { title: "Types", value: "All types of Medium voltage distribution" },
      { title: "Rating", value: "63A to 6300A / 1100V" },
      { title: "System", value: "3 Phase 3 Wire or 4 Wire" },
      { title: "Operational Voltage", value: "440 V / 660 V" },
      { title: "Short Circuit Current", value: "36 KA / 50 KA for 1 Sec" },
      { title: "Degree of Production", value: "IP 42, IP 54, IP 55, IP 65" },
      { title: "Bus Bar", value: "Aluminum / Copper" },
      { title: "BIS Standard", value: "IEC 60204-1, 60439 – 1&2, IS: 8623" },
    ],
  },
  {
    id: 9,
    img: [
      "https://ionvidle.sirv.com/krico/Medium%20voltage%20panel/AUTOMATIC%20POWER%20FACTOR%20CONTROL.png",
    ],
    description: [],
    category: "mv",
    title: "Automatic Power Factor Control",
    spec: [
      {
        title: "Types",
        value:
          "with Harmonic filter, without harmonic filter. Improving the network power factor",
      },
      { title: "Rating", value: "63A to 6300A / 1100V" },
      { title: "System", value: "3 Phase 3 Wire or 4 Wire" },
      { title: "Operational Voltage", value: "440 V / 660 V" },
      { title: "Short Circuit Current", value: "36 KA / 50 KA for 1 Sec" },
      { title: "Degree of Production", value: "IP 42, IP 54, IP 55, IP 65" },
      { title: "Bus Bar", value: "Aluminum / Copper" },
      { title: "BIS Standard", value: "IEC 60204-1, 60439 – 1&2, IS: 8623" },
    ],
  },
  {
    id: 10,
    img: [
      "https://ionvidle.sirv.com/krico/Medium%20voltage%20panel/motor%20control%20center%20panel.png",
    ],
    description: [],
    category: "mv",
    title: "Motor Control Center Panel",
    spec: [
      {
        title: "Types",
        value:
          "All type of motor control, VFD Starter, Soft strter panels, Motor products cooler",
      },
      { title: "Rating", value: "63A to 6300A / 1100V" },
      { title: "System", value: "3 Phase 3 Wire or 4 Wire" },
      { title: "Operational Voltage", value: "440 V / 660 V" },
      { title: "Short Circuit Current", value: "36 KA / 50 KA for 1 Sec" },
      { title: "Degree of Production", value: "IP 42, IP 54, IP 55, IP 65" },
      { title: "Bus Bar", value: "Aluminum / Copper" },
      { title: "BIS Standard", value: "IEC 60204-1, 60439 – 1&2, IS: 8623" },
    ],
  },
  {
    id: 11,
    img: [
      "https://ionvidle.sirv.com/krico/Medium%20voltage%20panel/PLC%20SCADA%20PANEL.png",
    ],
    description: [],
    category: "mv",
    title: "PLC – SCADA Panel",
    spec: [
      { title: "Types", value: "All type of automatic PLC Control panel" },
      { title: "Rating", value: "63A to 6300A / 1100V" },
      { title: "System", value: "3 Phase 3 Wire or 4 Wire" },
      { title: "Operational Voltage", value: "440 V / 660 V" },
      { title: "Short Circuit Current", value: "36 KA / 50 KA for 1 Sec" },
      { title: "Degree of Production", value: "IP 42, IP 54, IP 55, IP 65" },
      { title: "Bus Bar", value: "Aluminum / Copper" },
      { title: "BIS Standard", value: "IEC 60204-1, 60439 – 1&2, IS: 8623" },
    ],
  },
];

export const LVolt = [
  {
    id: 12,
    img: [
      "https://ionvidle.sirv.com/krico/low%20voltage%20panel/LTCT%20METER%20BOX.png",
    ],
    description: [],
    category: "lv",
    title: "LTCT Meter Box",
    spec: [
      { title: "Types", value: "150kV" },
      { title: "Rating", value: "63A to 6300A / 1100V" },
      { title: "System", value: "3 Phase 3 Wire or 4 Wire" },
      { title: "Operational Voltage", value: "440 V / 660 V" },
      { title: "Short Circuit Current", value: "36 KA / 50 KA for 1 Sec" },
      { title: "Degree of Production", value: "IP 42, IP 54, IP 55, IP 65" },
      { title: "Bus Bar", value: "Aluminum / Copper" },
      { title: "BIS Standard", value: "IEC 60204-1, 60439 – 1&2, IS: 8623" },
    ],
  },
  {
    id: 13,
    img: [
      "https://ionvidle.sirv.com/krico/low%20voltage%20panel/Eb%20meter%20box%20busbar.png",
    ],
    description: [],
    category: "lv",
    title: "EB Meter Box Busbar",
    spec: [
      { title: "Rating", value: "63A to 6300A / 1100V" },
      { title: "System", value: "3 Phase 3 Wire or 4 Wire" },
      { title: "Operational Voltage", value: "440 V / 660 V" },
      { title: "Short Circuit Current", value: "36 KA / 50 KA for 1 Sec" },
      { title: "Degree of Production", value: "IP 42, IP 54, IP 55, IP 65" },
      { title: "Bus Bar", value: "Aluminum / Copper" },
      { title: "BIS Standard", value: "IEC 60204-1, 60439 – 1&2, IS: 8623" },
    ],
  },
  {
    id: 14,
    img: [
      "https://ionvidle.sirv.com/krico/low%20voltage%20panel/VTPN%20DISTRIBUT%E2%80%ADION%20PANEL.png",
    ],
    description: [],
    category: "lv",
    title: "VTPN Distribution Boards",
    spec: [
      { title: "Rating", value: "63A to 6300A / 1100V" },
      { title: "System", value: "3 Phase 3 Wire or 4 Wire" },
      { title: "Operational Voltage", value: "440 V / 660 V" },
      { title: "Short Circuit Current", value: "36 KA / 50 KA for 1 Sec" },
      { title: "Degree of Production", value: "IP 42, IP 54, IP 55, IP 65" },
      { title: "Bus Bar", value: "Aluminum / Copper" },
      { title: "BIS Standard", value: "IEC 60204-1, 60439 – 1&2, IS: 8623" },
    ],
  },
  {
    id: 15,
    img: ["https://ionvidle.sirv.com/krico/Medium%20voltage%20panel/IMG-20241111-WA0003.jpg"],
    description: [],
    category: "lv",
    title: "MCB Distribution Boards",
    spec: [
      { title: "Rating", value: "63A to 6300A / 1100V" },
      { title: "System", value: "3 Phase 3 Wire or 4 Wire" },
      { title: "Operational Voltage", value: "440 V / 660 V" },
      { title: "Short Circuit Current", value: "36 KA / 50 KA for 1 Sec" },
      { title: "Degree of Production", value: "IP 42, IP 54, IP 55, IP 65" },
      { title: "Bus Bar", value: "Aluminum / Copper" },
      { title: "BIS Standard", value: "IEC 60204-1, 60439 – 1&2, IS: 8623" },
    ],
  },
  {
    id: 16,
    img: [
      "https://ionvidle.sirv.com/krico/low%20voltage%20panel/LIGHT%20DISTRIBUTION%20PANEL.png",
    ],
    description: [],
    category: "lv",
    title: "Lighting Distribution Panel",
    spec: [
      { title: "Rating", value: "63A to 6300A / 1100V" },
      { title: "System", value: "3 Phase 3 Wire or 4 Wire" },
      { title: "Operational Voltage", value: "440 V / 660 V" },
      { title: "Short Circuit Current", value: "36 KA / 50 KA for 1 Sec" },
      { title: "Degree of Production", value: "IP 42, IP 54, IP 55, IP 65" },
      { title: "Bus Bar", value: "Aluminum / Copper" },
      { title: "BIS Standard", value: "IEC 60204-1, 60439 – 1&2, IS: 8623" },
    ],
  },
];

export const Products = [
  {
    id: "hv",
    title: "High Voltage Panel",
    items: HVolt,
    range: "12-13KV/1250 A",
    description: [
      "High-voltage panels are essential in handling and managing electricity above 38,000 volts, making them fundamental components in grid stations, power plants, and large-scale industries. These enclosures are responsible for safely regulating and distributing vast amounts of power through specialized components, including circuit breakers, surge arresters, and transformers. Designed to withstand extreme voltage levels, high-voltage panels play a crucial role in ensuring safe and stable transmission across extensive networks. Each panel is custom-engineered for specific applications, incorporating intricate safety measures and protective relays to mitigate risks and prevent faults. Advanced monitoring systems embedded within these panels enhance real-time oversight and operational reliability. By ensuring efficient power management, these high-voltage panels support the seamless electricity supply to cities and industries. Ultimately, they are indispensable in upholding grid stability and managing substantial power demands across diverse sectors.",
    ],
    category: "",
  },
  {
    id: "mv",
    title: "Medium Voltage Panel",
    items: MVolt,
    range: "630-6300A/1100V",
    description: [
      "Medium-voltage panels are vital enclosures designed to manage electrical power between 600 and 38,000 volts, serving essential roles in industrial and utility applications. These panels facilitate controlled energy transmission using components such as switches, circuit breakers, and transformers, tailored to safely handle a wide range of medium voltages. With each panel custom-built for its application, they are equipped with advanced protective features to prevent electrical faults and enhance operational reliability. Engineered for efficient power distribution, medium-voltage panels ensure safe, uninterrupted energy flow across large systems. They are especially crucial in substations, power plants, and expansive industrial settings, where reliability is paramount. These panels contribute significantly to continuous power supply and system integrity by supporting stable energy distribution. Ultimately, they are indispensable in managing medium-voltage power across complex electrical networks.",
    ],
  },
  {
    id: "lv",
    title: "Low Voltage Panel",
    items: LVolt,
    range: "63-400A/660V",
    description: [
      "Low voltage control panels are essential enclosures designed to manage and distribute power at levels up to 600 volts, making them critical in industrial, commercial, and residential settings. These panels house key components like circuit breakers, relays, and switches, allowing centralized control of various electrical systems. By regulating power flow, they provide crucial safeguards against overloads and short circuits, enhancing system safety and reliability. Low-voltage panels play a significant role in automation, enabling the seamless operation of machinery, lighting, and other applications. Their customizable design allows for efficient monitoring, quick troubleshooting, and straightforward maintenance, ensuring operational continuity. Adaptable to a range of applications, these panels form the backbone of electrical infrastructure. Ultimately, they are indispensable in supporting safe, efficient power management across diverse environments.",
    ],
  },
];
