import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";

function Clients() {
  const img = [
    "https://ionvidle.sirv.com/krico/client/1683821521584.png",
    "https://ionvidle.sirv.com/krico/client/Ansell-Primary-Corporate-Logo-Blue-16-9.webp",
    "https://ionvidle.sirv.com/krico/client/britannia_industries1.png",
    "https://ionvidle.sirv.com/krico/client/images.png",
    "https://ionvidle.sirv.com/krico/client/logo.png",
    "https://ionvidle.sirv.com/krico/client/logo%20(2).png",
    "https://ionvidle.sirv.com/krico/client/logo_new-2.png",
    "https://ionvidle.sirv.com/krico/client/logo%20(1).png",
    "https://ionvidle.sirv.com/krico/client/Marico_Logo.svg.png",
    "https://ionvidle.sirv.com/krico/client/maxresdefault.png",
    "https://ionvidle.sirv.com/krico/client/MilkyMist_Dairy.png",
    "https://ionvidle.sirv.com/krico/client/Payal-Plastichem-New.png",
    "https://ionvidle.sirv.com/krico/client/pidilite.png",
    "https://ionvidle.sirv.com/krico/client/TANGEDCOpng",
  ];
  return (
    <Swiper
      autoplay={{
        delay: 2000,
        disableOnInteraction: false,
      }}
      centeredSlides={true}
      modules={[Autoplay]}
      slidesPerView={1}
      loop={true}
      breakpoints={{
        1024: {
          slidesPerView: 3,
        },
        1536: {
          slidesPerView: 5,
        },
      }}
      className='w-full h-60 mt-24'
      data-aos='zoom-in'
      data-aos-duration='2000'
    >
      {img.map((ele) => (
        <SwiperSlide key={ele}>
          <Client img={ele} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

const Client = ({ img }) => {
  return (
    <div className='clay bg-yellow-300 flex-shrink-0 m-auto relative overflow-hidden max-w-xs clay w-60 h-40 lg:w-48 border-2 border-sky-700 flex justify-center items-center'>
      <img className='relative w-40' src={img} alt='' />
    </div>
  );
};

export default Clients;
