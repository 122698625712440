import { Link, useParams } from "react-router-dom";

import "swiper/css";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { ScrollToTop } from "../../../utils/utils";
import { Products } from "../productlist/products";

const ProductDescription = () => {
  let { pid, currentCategory } = useParams();
  const category = Products.find((c) => c.id.toString() === currentCategory);
  const product = category.items.find((p) => p.id.toString() === pid);
  document.title = `Krico | ${product.title}`;

  return (
    <div className='p-4 leading-8 w-full'>
      <div className='mb-8 bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-50 p-12 text-slate-700'>
        <h1 className='my-8 text-2xl font-extrabold leading-none tracking-tight uppercase text-sky-700 lg:text-3xl grid place-items-center'>
          {product.title}
        </h1>

        <div className='mb-10 text-lg font-normal flex xl:flex-row flex-col w-full h-full'>
          <img
            className='object-fill rounded-xl overflow-hidden flex justify-center place-items-center h-full w-full basis-1/3 py-8'
            src={product.img}
            alt={product.title}
          />

          <div className='flex justify-center  p-8 w-full basis-2/3 h-full grow'>
            <div>
              <div>
                {product.description.map((description, i) => (
                  <div key={i}>{description}</div>
                ))}
              </div>
              <div>
                {product.spec.map((specification, i) => (
                  <div
                    className='py-2 grid grid-cols-12 hover:bg-slate-700 hover:text-white hover:bg-clip-padding hover:backdrop-filter hover:backdrop-blur-xl hover:bg-opacity-50 px-8'
                    key={i}
                  >
                    <div className='font-bold pb-2 col-span-4'>
                      {specification.title}
                    </div>
                    <div className='col-span-1'>:</div>
                    <div className='font-normal col-span-7'>
                      {specification.value}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Swiper
        modules={[Autoplay]}
        autoplay={{
          delay: 10000,
          stopOnLastSlide: false,
          pauseOnMouseEnter: true,
        }}
        breakpoints={{
          680: {
            slidesPerView: 2,
          },
          1500: {
            slidesPerView: 3,
          },
        }}
        loop={true}
      >
        {category.items.map((product, id) => (
          <div key={id}>
            {product.id.toString() !== pid && (
              <SwiperSlide className='pb-8'>
                <ProductCard product={product} category={currentCategory} />
              </SwiperSlide>
            )}
          </div>
        ))}
      </Swiper>
    </div>
  );
};

const ProductCard = ({ product, category }) => {
  return (
    // <Link
    //   to={`/product/${category}/${product.id}`}
    //   onClick={ScrollToTop}
    //   className='relative w-full max-w-xs m-auto flex flex-col rounded-lg border bg-white shadow-2xl transition-all ease-linear duration-200 hover:shadow-lime-100 hover:scale-105'
    // >
    //   {/* image section */}
    //   <div className='relative h-full w-full flex justify-center p-4'>
    //     <img
    //       className='object-cover rounded-xl shadow-md h-64'
    //       src={product.img}
    //       alt={product.title}
    //     />
    //     <span className='absolute top-0 left-0 m-2 rounded-full bg-black px-2 text-center text-sm font-medium text-white'>
    //       test
    //     </span>
    //   </div>

    //   {/* body section */}
    //   <div className='mt-4 px-5 pb-5 flex flex-col h-full'>
    //     <div className='mt-2 mb-5 flex justify-between flex-1'>
    //       <div>
    //         <h5 className='text-xl tracking-tight text-slate-900 h-12 line-clamp-2'>
    //           {product.title}
    //         </h5>
    //       </div>
    //       <div>
    //         <div className='mr-2 ml-3 rounded bg-yellow-200 px-2.5 py-0.5 text-xs font-semibold uppercase'>
    //           {category}
    //         </div>
    //       </div>
    //     </div>

    //     <div className='flex items-center justify-center rounded-md px-5 py-2.5 text-center text-sm font-medium text-slate-800 bg-lime-400 hover:bg-red-700 hover:text-slate-100'>
    //       <FaRegEye className='mr-2 h-6 w-6' />
    //       View
    //     </div>
    //   </div>
    // </Link>

    <Link
      to={`/product/${category}/${product.id}`}
      onClick={ScrollToTop}
      class="m-10 hover:text-white group before:hover:scale-95 before:hover:h-96 before:hover:w-80  before:hover:rounded-b-2xl before:transition-all before:duration-500 before:content-[''] before:w-80 before:h-24 before:rounded-t-2xl before:bg-gradient-to-r from-red-700 to-sky-700 before:absolute before:top-0 w-80 h-96 relative flex flex-col items-center justify-center gap-2 text-center rounded-2xl overflow-hidden bg-slate-50 bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-50"
    >
      <div class='w-60 h-60 bg-slate-100 mt-8 rounded-full border-4 border-yellow-300 z-10 group-hover:scale-150 group-hover:-translate-x-24  group-hover:-translate-y-20 transition-all duration-500'>
        <img
          src={product.img[0]}
          alt={product.title}
          class='w-60 h-60 object-fill rounded-full'
        />
      </div>
      <div class='z-10  group-hover:-translate-y-10 transition-all duration-500'>
        <span class='text-2xl font-semibold'>{product.title}</span>
      </div>
      <div class='bg-sky-700 px-4 py-1 text-slate-50 rounded-md z-10 hover:scale-125 transition-all duration-500 hover:bg-red-500 cursor-pointer'>
        View
      </div>
    </Link>
  );
};
export default ProductDescription;
