import { Link } from "react-router-dom";
import icon from "../../img/icon.png";
import { useEffect, useRef, useState } from "react";
import { ScrollToTop } from "../../utils/utils";
import { IoMenu } from "react-icons/io5";
import { FaAngleDown } from "react-icons/fa";
import { FiSun, FiMoon } from "react-icons/fi";

// Dark mode functions
const initializeDarkMode = () => {
  if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
    document.documentElement.classList.add('dark');
    return true;
  } else {
    document.documentElement.classList.remove('dark');
    return false;
  }
};

const toggleDarkMode = (isDark) => {
  if (isDark) {
    document.documentElement.classList.add('dark');
    localStorage.theme = 'dark';
  } else {
    document.documentElement.classList.remove('dark');
    localStorage.theme = 'light';
  }
};

const menuItems = [
  { text: "Home", link: "/" },
  { text: "About", link: "/about" },
  {
    text: "Products",
    link: "#",
    submenu: [
      {
        text: "High Voltage Panel",
        link: "/products/hv",
        submenu: [
          { text: "Indoor VCB", link: "/product/hv/1" },
          { text: "Outdoor VCB", link: "/product/hv/2" },
          { text: "Load Break Switch", link: "/product/hv/3" },
          { text: "Ring Main Unit", link: "/product/hv/4" },
          { text: "Compact Substation", link: "/product/hv/5" },
        ],
      },
      {
        text: "Medium Voltage Panel",
        link: "/products/mv",
        submenu: [
          { text: "Air Circuit Breaker Panel", link: "/product/mv/6" },
          { text: "Main Voltage Panel", link: "/product/mv/7" },
          { text: "Sub Switch Board", link: "/product/mv/8" },
          { text: "Automatic Power Factor Control", link: "/product/mv/9" },
          { text: "Motor Control Center Panel", link: "/product/mv/10" },
          { text: "PLC – SCADA Panel", link: "11" },
        ],
      },
      {
        text: "Low Voltage Panel",
        link: "/products/lv",
        submenu: [
          { text: "LTCT Meter Box", link: "/product/lv/12" },
          { text: "EB Meter Box Busbar", link: "/product/lv/13" },
          { text: "VTPN Distribution Boards", link: "/product/lv/14" },
          { text: "MCB Distribution Boards", link: "/product/lv/15" },
          { text: "Lighting Distribution Panel", link: "/product/lv/16" },
        ],
      },
    ],
  },
  {
    text: "Service",
    link: "#",
    submenu: [
      { text: "Electrical Consultancy", link: "/service/consultancy" },
      {
        text: "Electrical Engineering Contractors",
        link: "/service/contractors",
      },
      { text: "Maintenance", link: "/service/maintenance" },
    ],
  },
  { text: "Infrastructure", link: "/infrastructure" },
  { text: "Projects", link: "/projects" },
  { text: "Contact", link: "/contact" },
];

const displayMobileMenu = () => {
  document.getElementById("mobile-menu").classList.toggle("hidden");
  const isSmall = document
    .getElementById("mobile-menu")
    .classList.contains("hidden");

  const classList = ["h-16", "sm:h-20", "border-b-2"];
  const iconClassList = ["scale-75"];
  if (window.scrollY > 64) {
    if (isSmall) {
      classList.map((cls) => document.getElementById("nav").classList.add(cls));
      iconClassList.map((cls) =>
        document.getElementById("icon").classList.add(cls)
      );
    } else {
      classList.map((cls) =>
        document.getElementById("nav").classList.remove(cls)
      );
      iconClassList.map((cls) =>
        document.getElementById("icon").classList.remove(cls)
      );
    }
  }
};

const NavBar = () => {
  const [isDarkMode, setIsDarkMode] = useState(initializeDarkMode);

  const handleDarkModeToggle = () => {
    setIsDarkMode(!isDarkMode);
    toggleDarkMode(!isDarkMode);
  };

  return (
    <>
      <nav id='menu' className="relative">
        <div className='px-4 lg:px-12'>
          <div className='flex justify-between'>
            <div className='flex space-x-8'>
              {/* Logo */}
              <div>
                <Link
                  to='/'
                  className='flex items-center py-2 px-2'
                  onClick={ScrollToTop}
                >
                  <img
                    src={icon}
                    className='max-w-xs md:max-w-sm m-auto h-16 md:h-24 transition-all duration-300 ease-linear'
                    alt='logo'
                    id='icon'
                  />
                </Link>
              </div>

              <DestopView />
            </div>

            <div className='flex items-center space-x-4'>
              {/* Dark mode toggle */}
              <button
                onClick={handleDarkModeToggle}
                className='p-2 rounded-full text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-sky-500 transition-colors duration-200'
                aria-label={isDarkMode ? 'Switch to light mode' : 'Switch to dark mode'}
              >
                {isDarkMode ? (
                  <FiSun className='h-5 w-5' aria-hidden="true" />
                ) : (
                  <FiMoon className='h-5 w-5' aria-hidden="true" />
                )}
              </button>

              {/* Mobile menu button */}
              <div className='lg:hidden'>
                <button
                  id='mobile-menu-button'
                  onClick={displayMobileMenu}
                  aria-expanded="false"
                  aria-controls="mobile-menu"
                  className='p-2 rounded-md text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-sky-500'
                >
                  <IoMenu className='h-7 w-7' />
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Mobile menu */}
        <div
          className='hidden lg:hidden fixed inset-x-0 top-[88px] md:top-[112px] bottom-0 bg-white dark:bg-gray-900 overflow-y-auto'
          id='mobile-menu'
        >
          <MobileMenuItems />
        </div>
      </nav>
    </>
  );
};

const MobileMenuItems = () => {
  const [openMenus, setOpenMenus] = useState({});

  const handleMenuClick = (menuPath) => {
    setOpenMenus(prev => {
      const newState = { ...prev };
      
      // If menu is already open, close it
      if (newState[menuPath]) {
        delete newState[menuPath];
      } else {
        // Close any sibling or child menus
        Object.keys(newState).forEach(key => {
          if (key.startsWith(menuPath) || key.split('/').length === menuPath.split('/').length) {
            delete newState[key];
          }
        });
        // Open the clicked menu
        newState[menuPath] = true;
      }
      
      return newState;
    });
  };

  const MenuItem = ({ item, path = '' }) => {
    const currentPath = path ? `${path}/${item.text}` : item.text;
    const isOpen = openMenus[currentPath];

    if (!item.submenu) {
      return (
        <Link
          to={item.link}
          className="block px-4 py-2 text-gray-700 dark:text-gray-200 hover:text-sky-600 dark:hover:text-sky-400 transform transition-all duration-200 hover:translate-x-1"
          onClick={() => {
            ScrollToTop();
            displayMobileMenu();
          }}
        >
          {item.text}
        </Link>
      );
    }

    return (
      <div className="py-1">
        <div
          onClick={() => handleMenuClick(currentPath)}
          className="flex items-center justify-between px-4 py-2 text-gray-700 dark:text-gray-200 hover:text-sky-600 dark:hover:text-sky-400 cursor-pointer transform transition-all duration-200 hover:translate-x-1"
        >
          <span>{item.text}</span>
          <FaAngleDown
            className={`ml-2 w-5 h-5 text-sky-500 dark:text-sky-400 transition-transform duration-300 ease-in-out ${
              isOpen ? 'rotate-180' : ''
            }`}
          />
        </div>
        
        <div
          className={`pl-4 border-l-2 border-gray-200 dark:border-gray-700 overflow-hidden transition-all duration-300 ease-in-out ${
            isOpen ? 'max-h-[2000px] opacity-100' : 'max-h-0 opacity-0'
          }`}
          style={{
            transform: isOpen ? 'translateX(0)' : 'translateX(-10px)',
            transition: 'all 0.3s ease-in-out'
          }}
        >
          {item.submenu.map((subItem, index) => (
            <MenuItem
              key={`${currentPath}/${subItem.text}-${index}`}
              item={subItem}
              path={currentPath}
            />
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="py-4 space-y-1">
      {menuItems.map((item, index) => (
        <MenuItem
          key={`${item.text}-${index}`}
          item={item}
        />
      ))}
    </div>
  );
};

const DestopView = () => {
  const BuildMenu = (menuItems) => {
    const BuildSubMenu = ({ item }) => {
      return (
        <div className="relative group">
          <Link 
            to={item.link}
            className="flex items-center px-4 py-2 text-gray-700 dark:text-gray-200 hover:text-sky-600 dark:hover:text-sky-400"
            onClick={ScrollToTop}
          >
            {item.text}
            <FaAngleDown className="ml-1 w-4 h-4 text-sky-600 dark:text-sky-400" />
          </Link>
          
          <div className="absolute left-0 mt-0 w-48 bg-white dark:bg-gray-800 rounded-md shadow-lg border border-gray-200 dark:border-gray-700 invisible group-hover:visible">
            {item.submenu.map((subItem, subIndex) => (
              <div key={`${subItem.text}-${subIndex}`} className="relative group/sub">
                {!subItem.submenu ? (
                  <Link
                    to={subItem.link}
                    className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-sky-600 dark:hover:text-sky-400"
                    onClick={ScrollToTop}
                  >
                    {subItem.text}
                  </Link>
                ) : (
                  <>
                    <div className="flex items-center justify-between px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-sky-600 dark:hover:text-sky-400">
                      {subItem.text}
                      <FaAngleDown className="w-3 h-3 text-sky-600 dark:text-sky-400 transform -rotate-90" />
                    </div>
                    
                    <div className="absolute left-full top-0 w-48 bg-white dark:bg-gray-800 rounded-md shadow-lg border border-gray-200 dark:border-gray-700 invisible group-hover/sub:visible">
                      {subItem.submenu.map((subSubItem, subSubIndex) => (
                        <Link
                          key={`${subSubItem.text}-${subSubIndex}`}
                          to={subSubItem.link}
                          className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-sky-600 dark:hover:text-sky-400"
                          onClick={ScrollToTop}
                        >
                          {subSubItem.text}
                        </Link>
                      ))}
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      );
    };
    return menuItems.map((item, index) => {
      if (!item.submenu) {
        return (
          <div
            className='w-full whitespace-nowrap hover:bg-gray-100 dark:hover:bg-gray-800'
            key={`${item.text}-${index}`}
          >
            <Link
              to={item.link}
              className='block py-2 px-4 text-gray-700 dark:text-gray-200 hover:text-sky-600 dark:hover:text-sky-400'
              onClick={ScrollToTop}
            >
              {item.text}
            </Link>
          </div>
        );
      } else {
        return (
          <div key={`${item.text}-${index}`}>
            <BuildSubMenu item={item} />
          </div>
        );
      }
    });
  };
  return (
    <div
      className='hidden lg:flex items-center space-x-1'
      key={"desktop"}
    >
      {BuildMenu(menuItems)}
    </div>
  );
};

function NavigationBar() {
  useEffect(() => {
    const scroller = () => {
      const shouldAnimate = document
        .getElementById("mobile-menu")
        .classList.contains("hidden");
      const classList = ["h-16", "sm:h-20", "border-b-2"];
      const iconClassList = ["scale-75"];
      if (shouldAnimate) {
        if (window.scrollY > 64) {
          classList.map((cls) => document.getElementById("nav").classList.add(cls));
          iconClassList.map((cls) =>
            document.getElementById("icon").classList.add(cls)
          );
        } else {
          classList.map((cls) =>
            document.getElementById("nav").classList.remove(cls)
          );
          iconClassList.map((cls) =>
            document.getElementById("icon").classList.remove(cls)
          );
        }
      }
    };
    window.addEventListener("scroll", scroller);
    return () => window.removeEventListener("scroll", scroller);
  }, []);
  
  return (
    <>
      <header
        id='nav'
        className='items-center z-50 transition-all duration-500 ease-linear fixed w-full bg-white dark:bg-gray-900 border-b border-gray-200 dark:border-gray-700'
      >
        <NavBar />
      </header>
    </>
  );
}

export default NavigationBar;
