import { useParams } from "react-router-dom";
import { Projects as projects } from "../productlist/projects";

const ProjectDescription = () => {
  let { pid } = useParams();
  const project = projects.items.find((c) => c.id.toString() === pid);
  document.title = `Krico | ${project.title}`;

  return (
    <div className='p-4 text-slate-800 leading-8 w-full'>
      <div className='mb-8 '>
        <h1 className='my-8 text-2xl font-extrabold leading-none tracking-tight uppercase text-red-800 lg:text-3xl grid place-items-center'>
          {project.title}
        </h1>

        <div className='grid  grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-8'>
          {project.img.map((src, i) => (
            <div
              className='h-96 shadow-2xl rounded-xl hover:shadow-lime-500 hover:scale-110 transition-all ease-linear duration-300 p-2 border-b-8 border-r-8 border-double border-lime-400 hover:border-0'
              key={i}
            >
              <img
                src={src}
                alt={project.title}
                className='w-full h-full object-fill rounded-xl'
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectDescription;
