function CPDESC() {
  return (
    <section
      className='bg-slate-50 bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-50 mt-16 border-2 border-yellow-200 mx-4'
      data-aos='zoom-in-down'
    >
      <div className='mx-auto max-w-screen-xl py-16 px-6'>
        <div className='mx-auto max-w-screen-md mb-8 lg:mb-12 text-gray-700'>
          <h2 className='mb-4 text-3xl tracking-tight font-extrabold text-center'>
            What is a control panel?
          </h2>
          <p className='mb-2 text-base leading-9 tracking-wide'>
            A control panel is a crucial enclosure that houses components like
            circuit breakers, relays, and meters to monitor and distribute
            electrical power to various applications. Acting as centralized
            hubs, these panels facilitate automation and protection, ensuring
            optimal operation of power systems in industrial and commercial
            settings. By enabling real-time monitoring and diagnostics, control
            panels help identify and address faults swiftly, reducing downtime
            and preventing potential damage. They provide operators with a
            user-friendly interface to manage components efficiently while
            promoting safety. Control panels enhance productivity and
            reliability, which is essential for effective power management.
          </p>
        </div>
      </div>
    </section>
  );
}

export default CPDESC;
