import { Link, useParams } from "react-router-dom";

import { Products } from "./products";
import { Projects } from "./projects";

import { ScrollToTop } from "../../../utils/utils";

const ProductList = () => {
  let { page, currentCategory } = useParams();

  let pageInfo;
  let link;

  if (page === "projects") {
    pageInfo = Projects;
    link = "projects";
    document.title = `Krico | Projects`;
  } else if (page === "products") {
    pageInfo = Products.find((p) => p.id === currentCategory);
    link = `product/${currentCategory}`;
    document.title = `Krico | ${pageInfo?.title}`;
  }

  return (
    <div className='p-4 leading-8 mb-20'>
      <div className=' mb-8 bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-50 p-12'>
        <h1 className='my-8 text-2xl font-extrabold leading-none tracking-tight uppercase text-sky-700 lg:text-3xl grid place-items-center'>
          {pageInfo?.title}
        </h1>
        <h1 className='my-8 text-xl font-extrabold leading-none lg:text-2xl grid place-items-center text-red-400'>
          {pageInfo?.range}
        </h1>
        <p className='mb-10 text-lg font-normal text-slate-700 tracking-wide'>
          {pageInfo?.description?.map((description, i) => (
            <div
              className='first-letter:uppercase first-letter:text-2xl first-letter:font-bold indent-16 pb-4 leading-8'
              key={i}
            >
              {description}
            </div>
          ))}
        </p>
      </div>

      <div className='grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3 '>
        {pageInfo?.items?.map((product, i) => (
          <div key={i}>
            <ProductCard product={product} link={link} />
          </div>
        ))}
      </div>
    </div>
  );
};

const ProductCard = ({ product, link }) => {
  return (
    <Link
      to={`/${link}/${product.id}`}
      onClick={ScrollToTop}
      class="m-10 hover:text-white group before:hover:scale-95 before:hover:h-96 before:hover:w-80  before:hover:rounded-b-2xl before:transition-all before:duration-500 before:content-[''] before:w-80 before:h-24 before:rounded-t-2xl before:bg-gradient-to-r from-red-700 to-sky-700 before:absolute before:top-0 w-80 h-96 relative flex flex-col items-center justify-center gap-2 text-center rounded-2xl overflow-hidden bg-slate-50 bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-50"
    >
      <div class='w-60 h-60 bg-slate-100 mt-8 rounded-full border-4 border-yellow-300 z-10 group-hover:scale-150 group-hover:-translate-x-24  group-hover:-translate-y-20 transition-all duration-500'>
        <img
          src={product.img[0]}
          alt={product.title}
          class='w-60 h-60 object-fill rounded-full'
        />
      </div>
      <div class='z-10  group-hover:-translate-y-10 transition-all duration-500'>
        <span class='text-2xl font-semibold'>{product.title}</span>
        {/* <p>{product.category}</p> */}
      </div>
      <div class='bg-sky-700 px-4 py-1 text-slate-50 rounded-md z-10 hover:scale-125 transition-all duration-500 hover:bg-red-500 cursor-pointer'>
        View
      </div>
    </Link>
  );
};
export default ProductList;
