import { Link, useNavigate } from "react-router-dom";
import { BsMailboxFlag } from "react-icons/bs";
import { SlPhone } from "react-icons/sl";
import { MdOutlineLocationOn } from "react-icons/md";
import { useState } from "react";
import { ScrollToTop } from "../../utils/utils";

const LinkBuilder = ({ product }) => {
  return (
    <div className='flex gap-2'>
      <span>&#10148; </span>
      <div className='mb-4'>
        <Link
          to={product.link}
          className=' hover:underline'
          onClick={ScrollToTop}
        >
          {product.text}
        </Link>
      </div>
    </div>
  );
};

export const Address = ({ addres, phone, email }) => {
  return (
    <>
      <ContactItem
        icon={<MdOutlineLocationOn className='w-6 h-6 mx-2' />}
        item={addres}
      />
      <ContactItem
        icon={<SlPhone className='w-6 h-6 mx-2' />}
        item={phone}
      />
      <ContactItem
        icon={<BsMailboxFlag className='w-6 h-6 mx-2' />}
        item={email}
      />
    </>
  );
};

const Items = ({ text, clk }) => {
  const [item, setItem] = useState(text);
  return (
    <div className='mb-0.5 cursor-pointer' onClick={() => clk(item, setItem)}>
      {item}
    </div>
  );
};

const ContactItem = ({ icon, item }) => {
  if (item?.text?.length) {
    return (
      <div className='flex items-start m-2 '>
        <div className='m-1'>{icon}</div>
        <span className='ml-2 overflow-clip break-words'>
          {item?.text.map((a, i) => (
            <Items text={a} clk={item.clk} key={i} />
          ))}
        </span>
      </div>
    );
  }
};

const Footer = () => {
  const Products = [
    {
      text: "Low Voltage Panel",
      link: "/products/lv",
    },
    {
      text: "Medium Voltage Panel",
      link: "/products/mv",
    },
    {
      text: "High Voltage Panel",
      link: "/products/hv",
    },
  ];

  const Services = [
    {
      text: "Electrical Consultancy",
      link: "/service/consultancy",
    },
    {
      text: "Electrical Engineering Contractors",
      link: "/service/contractors",
    },
    {
      text: "Maintenance",
      link: "/service/maintenance",
    },
  ];

  const copyContactItems = (text, setText) => {
    navigator.clipboard.writeText(text);
    setText("copied");
    setTimeout(() => {
      setText(text);
    }, 2000);
  };

  const navigate = useNavigate();
  const showMap = () => {
    navigate("/contact");
  };

  return (
    <div className='relative mt-32 bg-sky-700'>
      <svg
        viewBox='0 0 1440 320'
        preserveAspectRatio='none'
        className='absolute w-full top-0 h-6 -mt-5 sm:-mt-16 sm:h-16 text-sky-700'
      >
        <path
          fill='currentColor'
          d='M0,224L11.4,213.3C22.9,203,46,181,69,154.7C91.4,128,114,96,137,85.3C160,75,183,85,206,106.7C228.6,128,251,160,274,176C297.1,192,320,192,343,165.3C365.7,139,389,85,411,58.7C434.3,32,457,32,480,64C502.9,96,526,160,549,202.7C571.4,245,594,267,617,234.7C640,203,663,117,686,117.3C708.6,117,731,203,754,240C777.1,277,800,267,823,266.7C845.7,267,869,277,891,266.7C914.3,256,937,224,960,224C982.9,224,1006,256,1029,245.3C1051.4,235,1074,181,1097,138.7C1120,96,1143,64,1166,53.3C1188.6,43,1211,53,1234,69.3C1257.1,85,1280,107,1303,122.7C1325.7,139,1349,149,1371,144C1394.3,139,1417,117,1429,106.7L1440,96L1440,320L1428.6,320C1417.1,320,1394,320,1371,320C1348.6,320,1326,320,1303,320C1280,320,1257,320,1234,320C1211.4,320,1189,320,1166,320C1142.9,320,1120,320,1097,320C1074.3,320,1051,320,1029,320C1005.7,320,983,320,960,320C937.1,320,914,320,891,320C868.6,320,846,320,823,320C800,320,777,320,754,320C731.4,320,709,320,686,320C662.9,320,640,320,617,320C594.3,320,571,320,549,320C525.7,320,503,320,480,320C457.1,320,434,320,411,320C388.6,320,366,320,343,320C320,320,297,320,274,320C251.4,320,229,320,206,320C182.9,320,160,320,137,320C114.3,320,91,320,69,320C45.7,320,23,320,11,320L0,320Z'
        ></path>
      </svg>

      <footer className='text-white'>
        <div className='mx-auto w-full max-w-screen-xl'>
          <div className='grid grid-cols-2 gap-8 px-10 py-16 lg:py-8 md:grid-cols-4'>
            <div>
              <h2 className='mb-6 text-sm font-semibold uppercase'>
                Products
              </h2>
              <div className='font-medium'>
                {Products.map((product, index) => (
                  <LinkBuilder product={product} key={index} />
                ))}
              </div>
            </div>
            <div>
              <h2 className='mb-6 text-sm font-semibold uppercase'>
                Services
              </h2>
              <div className='font-medium'>
                {Services.map((service, index) => (
                  <LinkBuilder product={service} key={index} />
                ))}
              </div>
            </div>
            <div className='col-span-2'>
              <h2 className='mb-6 text-sm font-semibold uppercase'>
                Contact us
              </h2>
              <div className='font-medium'>
                <Address
                  addres={{
                    clk: showMap,
                    text: [
                      "101, Jubail 31951",
                      "Kingdom of Saudi Arabia",
                    ],
                  }}
                  phone={{
                    clk: copyContactItems,
                    text: [
                      "+91 96889 10064",
                    ],
                  }}
                  email={{
                    clk: copyContactItems,
                    text: [
                      "sales@kricoelectric.com ",
                      "admin@kricoelectric.com ",
                      "kricoglobalpurchase21@gmail.com",
                    ],
                  }}
                />
              </div>
            </div>
          </div>
          <div className='px-4 py-6 md:flex md:items-center md:justify-between'>
            <span className='text-sm text-white  sm:text-center'>
              © {new Date().getFullYear()}{" "}
              <Link to='/' className='underline' onClick={ScrollToTop}>
                KRICO PANNEL
              </Link>
              . All Rights Reserved.
            </span>
            {/* <div className='flex mt-4 sm:justify-center md:mt-0 space-x-5 rtl:space-x-reverse'>
              <a href='#' className='text-white hover:text-gray-900 '>
                <svg
                  className='w-4 h-4'
                  aria-hidden='true'
                  
                  fill='currentColor'
                  viewBox='0 0 8 19'
                >
                  <path
                    fill-rule='evenodd'
                    d='M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z'
                    clip-rule='evenodd'
                  />
                </svg>
                <span className='sr-only'>Facebook page</span>
              </a>
              <a href='#' className='text-white hover:text-gray-900 '>
                <svg
                  className='w-4 h-4'
                  aria-hidden='true'
                  
                  fill='currentColor'
                  viewBox='0 0 21 16'
                >
                  <path d='M16.942 1.556a16.3 16.3 0 0 0-4.126-1.3 12.04 12.04 0 0 0-.529 1.1 15.175 15.175 0 0 0-4.573 0 11.585 11.585 0 0 0-.535-1.1 16.274 16.274 0 0 0-4.129 1.3A17.392 17.392 0 0 0 .182 13.218a15.785 15.785 0 0 0 4.963 2.521c.41-.564.773-1.16 1.084-1.785a10.63 10.63 0 0 1-1.706-.83c.143-.106.283-.217.418-.33a11.664 11.664 0 0 0 10.118 0c.137.113.277.224.418.33-.544.328-1.116.606-1.71.832a12.52 12.52 0 0 0 1.084 1.785 16.46 16.46 0 0 0 5.064-2.595 17.286 17.286 0 0 0-2.973-11.59ZM6.678 10.813a1.941 1.941 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.919 1.919 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Zm6.644 0a1.94 1.94 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.918 1.918 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Z' />
                </svg>
                <span className='sr-only'>Discord community</span>
              </a>
              <a href='#' className='text-white hover:text-gray-900 '>
                <svg
                  className='w-4 h-4'
                  aria-hidden='true'
                  
                  fill='currentColor'
                  viewBox='0 0 20 17'
                >
                  <path
                    fill-rule='evenodd'
                    d='M20 1.892a8.178 8.178 0 0 1-2.355.635 4.074 4.074 0 0 0 1.8-2.235 8.344 8.344 0 0 1-2.605.98A4.13 4.13 0 0 0 13.85 0a4.068 4.068 0 0 0-4.1 4.038 4 4 0 0 0 .105.919A11.705 11.705 0 0 1 1.4.734a4.006 4.006 0 0 0 1.268 5.392 4.165 4.165 0 0 1-1.859-.5v.05A4.057 4.057 0 0 0 4.1 9.635a4.19 4.19 0 0 1-1.856.07 4.108 4.108 0 0 0 3.831 2.807A8.36 8.36 0 0 1 0 14.184 11.732 11.732 0 0 0 6.291 16 11.502 11.502 0 0 0 17.964 4.5c0-.177 0-.35-.012-.523A8.143 8.143 0 0 0 20 1.892Z'
                    clip-rule='evenodd'
                  />
                </svg>
                <span className='sr-only'>Twitter page</span>
              </a>
              <a href='#' className='text-white hover:text-gray-900 '>
                <svg
                  className='w-4 h-4'
                  aria-hidden='true'
                  
                  fill='currentColor'
                  viewBox='0 0 20 20'
                >
                  <path
                    fill-rule='evenodd'
                    d='M10 .333A9.911 9.911 0 0 0 6.866 19.65c.5.092.678-.215.678-.477 0-.237-.01-1.017-.014-1.845-2.757.6-3.338-1.169-3.338-1.169a2.627 2.627 0 0 0-1.1-1.451c-.9-.615.07-.6.07-.6a2.084 2.084 0 0 1 1.518 1.021 2.11 2.11 0 0 0 2.884.823c.044-.503.268-.973.63-1.325-2.2-.25-4.516-1.1-4.516-4.9A3.832 3.832 0 0 1 4.7 7.068a3.56 3.56 0 0 1 .095-2.623s.832-.266 2.726 1.016a9.409 9.409 0 0 1 4.962 0c1.89-1.282 2.717-1.016 2.717-1.016.366.83.402 1.768.1 2.623a3.827 3.827 0 0 1 1.02 2.659c0 3.807-2.319 4.644-4.525 4.889a2.366 2.366 0 0 1 .673 1.834c0 1.326-.012 2.394-.012 2.72 0 .263.18.572.681.475A9.911 9.911 0 0 0 10 .333Z'
                    clip-rule='evenodd'
                  />
                </svg>
                <span className='sr-only'>GitHub account</span>
              </a>
              <a href='#' className='text-white hover:text-gray-900 '>
                <svg
                  className='w-4 h-4'
                  aria-hidden='true'
                  
                  fill='currentColor'
                  viewBox='0 0 20 20'
                >
                  <path
                    fill-rule='evenodd'
                    d='M10 0a10 10 0 1 0 10 10A10.009 10.009 0 0 0 10 0Zm6.613 4.614a8.523 8.523 0 0 1 1.93 5.32 20.094 20.094 0 0 0-5.949-.274c-.059-.149-.122-.292-.184-.441a23.879 23.879 0 0 0-.566-1.239 11.41 11.41 0 0 0 4.769-3.366ZM8 1.707a8.821 8.821 0 0 1 2-.238 8.5 8.5 0 0 1 5.664 2.152 9.608 9.608 0 0 1-4.476 3.087A45.758 45.758 0 0 0 8 1.707ZM1.642 8.262a8.57 8.57 0 0 1 4.73-5.981A53.998 53.998 0 0 1 9.54 7.222a32.078 32.078 0 0 1-7.9 1.04h.002Zm2.01 7.46a8.51 8.51 0 0 1-2.2-5.707v-.262a31.64 31.64 0 0 0 8.777-1.219c.243.477.477.964.692 1.449-.114.032-.227.067-.336.1a13.569 13.569 0 0 0-6.942 5.636l.009.003ZM10 18.556a8.508 8.508 0 0 1-5.243-1.8 11.717 11.717 0 0 1 6.7-5.332.509.509 0 0 1 .055-.02 35.65 35.65 0 0 1 1.819 6.476 8.476 8.476 0 0 1-3.331.676Zm4.772-1.462A37.232 37.232 0 0 0 13.113 11a12.513 12.513 0 0 1 5.321.364 8.56 8.56 0 0 1-3.66 5.73h-.002Z'
                    clip-rule='evenodd'
                  />
                </svg>
                <span className='sr-only'>Dribbble account</span>
              </a>
            </div> */}
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
