import TypeWriterEffect from "react-typewriter-effect";
import { TbTargetArrow } from "react-icons/tb";
import { IoTelescopeSharp } from "react-icons/io5";

function About() {
  document.title = `Krico | About`;
  return (
    <div className='p-4 text-slate-800 leading-8'>
      <div className=' mb-8 '>
        <h1 className='my-8 text-4xl font-extrabold leading-none tracking-tight uppercase text-red-800 md:text-5xl lg:text-6xl grid place-items-center'>
          About
        </h1>
        <div className='mb-10 text-lg font-semibold lg:text-2xl sm:px-16 xl:px-48 grid place-items-center'>
          KRICO PANNEL GLOBAL PRIVATE LIMITED
        </div>
      </div>
      <div className='grid grid-cols-1 gap-6 lg:grid-cols-2'>
        <div className='mb-8 clay p-4 col-s bg-green-100'>
          <div className='flex items-center place-content-center text-3xl font-extrabold my-6 text-green-700'>
            <TbTargetArrow className='scale-150 mx-4 ' />
            Mission
          </div>
          <div className='indent-16 p-4 grid grid-flow-row gap-8'>
            <TypeWriterEffect
              startDelay={100}
              typeSpeed={1}
              hideCursorAfterText
              cursorColor='black'
              text='KRICO PANNEL GLOBAL PRIVATE LIMITED shall be the most trusted, respected, and preferred brand, for Electrical Engineering Solution and Panel Boards Manufacturing that finds application in the Industrial, Commercial, Power Generation, Distribution, and Utilization of Energy Sectors.'
            />
            <TypeWriterEffect
              startDelay={100}
              typeSpeed={1}
              hideCursorAfterText
              cursorColor='black'
              text='In its major businesses Hall not only commands a domestic market and Export Market Share or more but is known widely as the company “closest to its Industrial Customers”'
            />
            <TypeWriterEffect
              startDelay={100}
              typeSpeed={1}
              hideCursorAfterText
              cursorColor='black'
              text='KRICO PANNEL GLOBAL PRIVATE LIMITED products shall be used to manage power in worldwide biggest industries, in its Power Sector Companies, and in its most critical infrastructure.'
            />
            <TypeWriterEffect
              startDelay={100}
              typeSpeed={1}
              hideCursorAfterText
              cursorColor='black'
              text='The KRICO PANNEL GLOBAL PRIVATE LIMITED name shall be recognized widely as a benchmark and shall serve as a role model and an inspiration to other Electrical Engineering &amp; Panel Board Manufacturing companies.'
            />
          </div>
        </div>
        <div className='mb-8 clay p-4 col-s bg-blue-100'>
          <div className='flex items-center place-content-center text-3xl font-extrabold my-6 text-blue-500 hover:text-green-500'>
            <IoTelescopeSharp className='scale-150 mx-4 ' />
            Vision
          </div>
          <div className='indent-16 p-4 grid grid-flow-row gap-8'>
            <TypeWriterEffect
              startDelay={100}
              typeSpeed={1}
              hideCursorAfterText
              cursorColor='black'
              text='To create a unique empower of outstanding products, operational excellence, path-breaking customer service, and compelling marketing'
            />
            <TypeWriterEffect
              startDelay={100}
              typeSpeed={1}
              hideCursorAfterText
              cursorColor='black'
              text='To create and relish a vibrant workplace where employees are empowered, cared for, developed, and most of all provided unlimited opportunity to discover their full potential.'
            />
            <TypeWriterEffect
              startDelay={100}
              typeSpeed={1}
              hideCursorAfterText
              cursorColor='black'
              text='To continuously enhance our vibrant technologies, and develop new world-class innovation products to expand worldwide.'
            />
            <TypeWriterEffect
              startDelay={100}
              typeSpeed={1}
              hideCursorAfterText
              cursorColor='black'
              text='To consolidate and strengthen our position as India’s largest exporter of Industrial power distribution control panels.'
            />
            <TypeWriterEffect
              startDelay={100}
              typeSpeed={1}
              hideCursorAfterText
              cursorColor='black'
              text='To earn a healthy return on investment.'
            />
            <TypeWriterEffect
              startDelay={100}
              typeSpeed={1}
              hideCursorAfterText
              cursorColor='black'
              text='To every day experience, the sheer joy of delighting our internal and external customers, and to relish the thrill of participation in the worldwide infrastructure boom.'
            />
          </div>
        </div>
      </div>
      <div className='text-slate-700 bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-50 p-12'>
        <div className='mb-8 indent-16 first-letter:font-extrabold first-letter:tracking-widest'>
          Krico Pannel is a leading manufacturer of high-quality electrical control panels, specializing in HV (High Voltage), LV (Low Voltage), and MV (Medium Voltage) solutions. With a steadfast commitment to quality, innovation, and customer satisfaction, Krico Pannel has become a trusted partner across industries such as power generation, oil and gas, manufacturing, and infrastructure.
        </div>
        <div className='grid grid-cols-1 gap-6 lg:grid-cols-3 '>
          <div className='mb-8 col-s indent-16 first-letter:font-extrabold first-letter:tracking-widest'>
            Our expertise lies in designing control panels tailored to meet the precise requirements of our clients, ensuring the safe and efficient operation of electrical systems. From HV panels that manage high- voltage power distribution with cutting-edge features like circuit breakers and protective relays, to LV panels that deliver precise control in commercial and industrial settings, we offer solutions that are built to last. Additionally, our MV panels, engineered for medium voltage applications (11kV to 36kV), provide reliable performance in power plants, substations, and large-scale industrial operations.
          </div>

          <div className='mb-8 col-span-2'>
            <iframe
              title='Krico'
              className='w-full h-80 lg:h-full'
              loading='lazy'
              src='https://drive.google.com/file/d/1ZJwIOoMpSwDcOGfAqbUU8qy6rhCCsSQ1/preview'
              frameborder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowfullscreen=''
            ></iframe>
          </div>
        </div>
        <div className='mb-8 indent-16 first-letter:font-extrabold first-letter:tracking-widest'>
          At Krico Pannel, we prioritize the highest standards of quality and reliability, reflected in our state-of- the-art manufacturing facilities and stringent quality control processes. Our team of skilled engineers works closely with clients to deliver customized solutions, staying at the forefront of technological advancements to offer the most advanced features in every product.
        </div>
        <div className='mb-8 indent-16 first-letter:font-extrabold first-letter:tracking-widest'>
          {/* <TypeWriterEffect
            startDelay={100}
            typeSpeed={1}
            hideCursorAfterText
            cursorColor='black'
            text=''
          /> */}
          With a proven track record of delivering safe, efficient, and innovative control panel solutions, Krico Pannel is committed to empowering industries worldwide.
        </div>
      </div>
    </div>
  );
}

export default About;
