import { Link } from "react-router-dom";
import { ScrollToTop } from "../../../utils/utils";

const NoPage = () => {
  return (
    <section>
      <div className='py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 h-screen flex justify-center place-items-center'>
        <div className='mx-auto max-w-screen-sm text-center'>
          <h1 className='mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600 dark:text-primary-500'>
            OOPS!
          </h1>
          <div className='mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl'>
            Something's missing.
          </div>
          <div className='mb-4 text-lg font-light text-gray-500 dark:text-gray-400'>
            Sorry, we can't find that page. You'll find lots to explore on the
            home page.
          </div>
          <Link
            to='/'
            onClick={ScrollToTop}
            className='inline-flex text-slate-800 bg-lime-500 hover:bg-red-800 hover:text-slate-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4'
          >
            Back to Homepage
          </Link>
        </div>
      </div>
    </section>
  );
};

export default NoPage;
