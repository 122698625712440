import { useState } from "react";
import { MdPermDeviceInformation } from "react-icons/md";
import { Address } from "../../footer";

const Contact = () => {
  const [src, setSrc] = useState(
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4351.808391807043!2d49.65635796524076!3d26.990845573863723!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e35a1b7ec81aa69%3A0xfd3de2103a858495!2s101%20Business%20Tower!5e0!3m2!1sen!2sin!4v1731306676194!5m2!1sen!2sin"
  );

  const copyContactItems = (text, setText) => {
    navigator.clipboard.writeText(text);
    setText("copied");
    setTimeout(() => {
      setText(text);
    }, 2000);
  };

  const setAddressText = (text, setText) => {
    setText("Loading Map");
    setTimeout(() => {
      setText(text);
    }, 2000);
  };

  document.title = `Krico | Contact`;

  return (
    <div className='p-4 text-slate-800 leading-8 w-full mb-8 '>
      <div className='bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-50 p-12 m-8'>
        <h1 className='py-10 text-4xl font-extrabold leading-none tracking-tight text-red-400 grid place-items-center'>
          <div className='grid place-content-center gap-4 leading-10'>
            <span className='flex justify-center gap-4'>Contact us</span>
          </div>
        </h1>
        <ContactForm />
      </div>

      <div className='grid grid-cols-1 lg:grid-cols-2 lg:gap-4 '>
        <div className=' shadow-xl h-96 lg:h-full mb-8'>
          <iframe
            src={src}
            title='google map'
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
            className='w-full h-full rounded-3xl'
          ></iframe>
        </div>
        <div className='p-4 clay bg-slate-200'>
          <div className='text-2xl font-bold mt-2 text-red-900'>Office</div>
          <Address
            addres={{
              clk: (text, setText) => {
                setSrc(
                  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4351.808391807043!2d49.65635796524076!3d26.990845573863723!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e35a1b7ec81aa69%3A0xfd3de2103a858495!2s101%20Business%20Tower!5e0!3m2!1sen!2sin!4v1731306676194!5m2!1sen!2sin"
                );
                setAddressText(text, setText);
              },
              text: [
                "#176 – Mettukadu, Chinnamedu,",
                "Thindal, Erode – 638 012,",
                "Tamilnadu, India.",
              ],
            }}
            phone={[]}
            email={[]}
          />
          <div className='text-2xl font-bold mt-4 text-red-900'>Factory</div>
          <Address
            addres={{
              clk: (text, setText) => {
                setSrc(
                  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4351.808391807043!2d49.65635796524076!3d26.990845573863723!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e35a1b7ec81aa69%3A0xfd3de2103a858495!2s101%20Business%20Tower!5e0!3m2!1sen!2sin!4v1731306676194!5m2!1sen!2sin"
                );
                setAddressText(text, setText);
              },
              text: [
                "KRICO PANNEL Global Private Limited,",
                "D.No.96/4, Thottipalayam Main Street,",
                "Nasiyanur Post, Erode - 638 107",
              ],
            }}
            phone={{
              clk: copyContactItems,
              text: ["+91 96889 10064"],
            }}
            email={{
              clk: copyContactItems,
              text: [
                "sales@kricoelectric.com ",
                "kricoglobalpurchase21@gmail.com",
              ],
            }}
          />
          <div className='text-2xl font-bold mt-4 text-red-900'>
            Global Office
          </div>
          <Address
            addres={{
              clk: (text, setText) => {
                setSrc(
                  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4351.808391807043!2d49.65635796524076!3d26.990845573863723!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e35a1b7ec81aa69%3A0xfd3de2103a858495!2s101%20Business%20Tower!5e0!3m2!1sen!2sin!4v1731306676194!5m2!1sen!2sin"
                );
                setAddressText(text, setText);
              },
              text: [
                "101, Jubail 31951",
                "Kingdom of Saudi Arabia",
              ],
            }}
            phone={{
              clk: copyContactItems,
              text: ["+966-55-359-9698", "+966-55-892-8453"],
            }}
            email={{
              clk: copyContactItems,
              text: ["admin@kricoelectric.com"],
            }}
          />
        </div>
      </div>
    </div>
  );
};

const ContactForm = () => {
  return (
    <div class='flex items-center justify-center p-12'>
      <div class='mx-auto w-full max-w-[550px]'>
        <form action='https://formbold.com/s/FORM_ID' method='POST'>
          <div class='relative mb-8'>
            <input
              type='text'
              id='disabled_outlined'
              class='block px-2.5 pb-2.5 pt-4 w-full text-sm text-slate-100 bg-transparent rounded-lg border-2  appearance-none  border-sky-700  focus:outline-none focus:ring-0  peer'
              placeholder=' '
            />
            <label
              for='disabled_outlined'
              class='absolute text-sm text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus:text-slate-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:-top-2 peer-focus:scale-150 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto'
            >
              Name
            </label>
          </div>
          <div class='relative mb-8'>
            <input
              type='mobile'
              id='disabled_outlined'
              class='block px-2.5 pb-2.5 pt-4 w-full text-sm text-slate-100 bg-transparent rounded-lg border-2  appearance-none  border-sky-700  focus:outline-none focus:ring-0  peer'
              placeholder=' '
            />
            <label
              for='disabled_outlined'
              class='absolute text-sm text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus:text-slate-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:-top-2 peer-focus:scale-150 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto'
            >
              Mobile
            </label>
          </div>
          <div class='relative mb-8'>
            <input
              type='email'
              id='disabled_outlined'
              class='block px-2.5 pb-2.5 pt-4 w-full text-sm text-slate-100 bg-transparent rounded-lg border-2  appearance-none  border-sky-700  focus:outline-none focus:ring-0  peer'
              placeholder=' '
            />
            <label
              for='disabled_outlined'
              class='absolute text-sm text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus:text-slate-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:-top-2 peer-focus:scale-150 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto'
            >
              Email
            </label>
          </div>
          <div class='relative mb-8'>
            <textarea
              rows={4}
              type='text'
              id='disabled_outlined'
              class='block px-2.5 pb-2.5 pt-4 w-full text-sm text-slate-100 bg-transparent rounded-lg border-2  appearance-none  border-sky-700  focus:outline-none focus:ring-0  peer'
              placeholder=''
            />
            <label
              for='disabled_outlined'
              class='absolute text-sm text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus:text-slate-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:-top-2 peer-focus:scale-150 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto'
            >
              Comments
            </label>
          </div>
          <div>
            <button class='hover:shadow-form rounded-md bg-sky-700 py-3 px-8 text-base font-semibold text-white outline-none'>
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
