import { useEffect } from "react";

import "./App.css";
import "aos/dist/aos.css";

import Aos from "aos";
import Home from "./component/home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./component/layout";
import About from "./component/pages/about";
import ProductList from "./component/pages/productlist";

import NoPage from "./component/pages/nopage";
import ProjectDescription from "./component/pages/projectdescription";
import ProductDescription from "./component/pages/productdescription";
import Service from "./component/pages/service";
import Infrastructure from "./component/pages/infrastructure";
import Contact from "./component/pages/contact";
import BanerV2 from "./component/hero/banner.v2";

function App() {
  useEffect(() => {
    Aos.init({
      delay: 300,
    });
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='test' element={<BanerV2 />} />
          <Route path='about' element={<About />} />
          <Route path='contact' element={<Contact />} />
          <Route path='infrastructure' element={<Infrastructure />} />
          <Route path=':page' element={<ProductList />} />
          <Route path='service/:selectedservice' element={<Service />} />
          <Route path=':page/:currentCategory' element={<ProductList />} />
          <Route path='projects/:pid' element={<ProjectDescription />} />
          <Route
            path='product/:currentCategory/:pid'
            element={<ProductDescription />}
          />
          <Route path='*' element={<NoPage />} />
          {/* <Route path='*' element={<NoPage />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
