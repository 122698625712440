import Clients from "../clients";
import Products from "../products";
import Projects from "../projects";
import BanerV2 from "../hero/banner.v2";
import CPDESC from "../cpdescription";
// import WhyKrico from "../whykrico";

function Home() {
  document.title = `Krico | Best electrical control panel manufacturer and suppliers in India`;
  return (
    <>
      <BanerV2 />
      {/* <WhyKrico /> */}
      <Products />
      <Clients />
      <CPDESC />
      <Projects />
    </>
  );
}

export default Home;
